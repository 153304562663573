import React, { useEffect, useState } from 'react'
import { useFetch } from "react-async"

export default function NewsFeed({ id }) {
  const { isPending, error, data, run } = useFetch(`https://publish.ne.cision.com/papi/Release/${id}?format=json`, { method: 'GET' })
  const [newsContent, setNewsContent] = useState(null);

  useEffect(() => {
    run()
  }, [run]);

  if (isPending) return "Loading..."
  if (error) return <div>The news couldn't be loaded, please check again later</div>

  if (data) {
    if (!data.bodyUsed) { data.json().then(setNewsContent) }
    if (!newsContent) return <div></div>
    return (
      <React.Fragment>
        <h2>{newsContent.Release.Title}</h2>
        <div dangerouslySetInnerHTML={{
          __html: newsContent.Release.HtmlBody.replace(/style=".*?"/g, ""),
        }}></div>
        {newsContent.Release.Files.length ? (
          <div>
            <hr className="margin-top margin-bottom" />
            <h2>Ladda ner</h2>
            <ul>
              {newsContent.Release.Files.map(File => (
                <li key={File.FileName}>
                  <a href={File.Url} target="_blank" rel="noopener">{File.Title || File.FileName}</a>
                </li>
              ))}
            </ul>
          </div>) : ''}
      </React.Fragment>
    )
  }
}
