import React from 'react'
import NewsContent from '../../components/NewsContent'
import Layout from '../../components/Layout'
import LocaleContext from '../../contexts/LocaleContext'
import InvestorRelationsLayout from '../../components/InvestorRelationsLayout'
import Menu from '../../components/Menu'
import { graphql } from 'gatsby'
import Footer from '../../components/Footer'

export const query = graphql`
  query ($locale: String!) {
    datoCmsNewMenu ( locale: { eq: $locale }){
      ...Menu
    }
    datoCmsFooter ( locale: { eq: $locale }) {
      ...Footer
    }
  }
  `

export default function SingleNews({ data, pageContext, location }) {
  const searchParams = new URLSearchParams(location.search)

  return (
    <LocaleContext.Provider value={pageContext.locale || 'en'}>
      <Layout locale={pageContext.locale}>
        <Menu
            transparent={true}
            menu={data.datoCmsNewMenu}
            socialMedia={data.datoCmsFooter.socialMedia}
          />
        <InvestorRelationsLayout>
          <React.Fragment>
            <div className="container center-width prose clearfix">
              <NewsContent id={searchParams.get('id')} />
            </div>
          </React.Fragment>
        </InvestorRelationsLayout>
        <Footer footer={data.datoCmsFooter} />
      </Layout>
    </LocaleContext.Provider>
  )
}
